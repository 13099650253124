import React, { useState } from "react";
import Avatar from "../common/Avatar";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiPhone } from "react-icons/fi";
import { IoCopyOutline } from "react-icons/io5";
import { toast } from "react-toastify";

const EnrolleeCard = ({
  profileImg,
  enrolleeName,
  enrolleeNum,
  allergyName,
  allergyDesc,
  contacts,
  link,
  handleEdit,
  handleDelete,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const url = window.location.href

  const onCopyClick = () => {
    navigator.clipboard
      .writeText(url + link)
      .then(() => {
        toast.success("Link copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="border border-stroke rounded-xl my-2 md:my-0 p-2 md:p-3 lg:p-4 2xl:p-5 flex flex-col md:gap-2.5 lg:gap-3 2xl:gap-4">
      <div className="flex justify-between items-center pb-4 border-b border-stroke">
        <div className="flex items-center md:gap-1.5 lg:gap-2.5 2xl:gap-3">
          <Avatar imgUrl={profileImg} />
          <div className="ml-1 md:ml-[0]">
            <h1 className="font-urbanistBold text-[14px] md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              {enrolleeName}
            </h1>
            <p className="text-[12px] md:text-[10px] lg:text-[12px] xl:text-[13px] 2xl:text-[14px] font-urbanistMedium text-bodyText">
              Enrollee {enrolleeNum}
            </p>
          </div>
        </div>
        <div
          className="rounded-full p-1 md:p-1 lg:p-1.5 2xl:p-2 border border-stroke relative cursor-pointer"
          onClick={() => setShowOptions(!showOptions)}
        >
          <BsThreeDotsVertical />
          {showOptions && (
            <div className="absolute w-[10rem] -right-12 bg-white flex flex-col shadow-md rounded-md font-urbanistMedium">
              <p className="px-4 py-2 hover:bg-gray-200" onClick={handleEdit}>
                Edit Enrollee
              </p>
              <p className="px-4 py-2 hover:bg-gray-200" onClick={handleDelete}>
                Delete Enrollee
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col mt-1 md:mt-0 gap-2 md:gap-1 2xl:gap-3 pb-4 border-b border-stroke">
        <p className="font-urbanistMedium text-[12px] md:text-[10px] lg:text-[12px] xl:text-[13px] 2xl:text-[14px]">
          ALLERGY INFORMATION
        </p>
        <div>
          <h1 className="font-urbanistBold text-[16px] md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
            {allergyName}
          </h1>
          <p className="text-[12px] md:text-[10px] lg:text-[12px] xl:text-[13px] 2xl:text-[14px] font-urbanistMedium text-bodyText">
            {allergyDesc}
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-3 pb-4 border-b border-stroke">
        <p className="font-urbanistMedium mt-2 md:mt-0 text-[12px] md:text-[10px] lg:text-[12px] xl:text-[13px] 2xl:text-[14px]">
          CONTACT INFORMATION
        </p>
        <div className="flex flex-col md:gap-2 2xl:gap-5">
          {contacts?.map((contact) => (
            <div className="flex items-center gap-2">
              <div className="text-primary text-[16px] md:text-[25px]">
                <FiPhone />
              </div>
              <p className="font-urbanistMedium text-[14px] md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                {contact}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-3">
        <p className="font-urbanistMedium mt-2 md:mt-0 text-[14px] md:text-[10px] lg:text-[12px] xl:text-[13px] 2xl:text-[14px]">
          GENERATED LINK
        </p>
        <div className="border border-stroke rounded-xl py-2 px-3 md:px-3 md:py-1 lg:p-2.5 2xl:p-3">
          <div className="flex items-center justify-between">
            <p className="font-urbanistMedium text-[12px] md:text-[10px] lg:text-[12px] xl:text-[13px] 2xl:text-[14px]">
              {link}
            </p>
            <div
              className="text-[16px] md:text-[25px] cursor-pointer"
              onClick={onCopyClick}
            >
              <IoCopyOutline />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrolleeCard;
