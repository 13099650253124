import React from 'react';

const Button = ({ type, variant, className, onClick, children }) => {
  let buttonClasses = 'font-manropeBold p-3 text-[11px] md:p-2 xl:p-3 md:text-[12px] xl:text-[16px] 2xl:py-3 2xl:px-4 rounded-xl focus:outline-none focus:shadow-outline whitespace-nowrap';

  if (variant === 'filled') {
    buttonClasses += ' bg-primary text-white';
  } else if (variant === 'outline') {
    buttonClasses += ' border border-primary text-primary hover:text-white hover:bg-primary transition-all duration-200 ease-in-out';
  } else if (variant === 'secondary-filled') {
    buttonClasses += ' bg-black text-white';
  }

  buttonClasses += ` ${className}`;

  return (
    <button onClick={onClick} type={type} className={buttonClasses}>
      {children}
    </button>
  );
};

export default Button;
