import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteEnrollee,
  getEnrolleesByUserId,
  reset,
} from "../features/enrollee/enrolleeSlice";

import { toast } from "react-toastify";
import axios from "axios";
import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";
import EnrolleeCard from "../components/dashboard/EnrolleeCard";
import Button from "../components/common/Button";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { isLoading, enrollees, isSuccess, isError, message } = useSelector((state) => state.enrollees);

  useEffect(() => {
    if (isSuccess && message) {
      toast.success(message);
    } else if (isError && message) {
      toast.error(message);
    }
  }, [isSuccess, isError, message]);

  const handleEdit = (enrollee) => {
    navigate("/add-enrollee", { state: { enrollee } });
  };

  const handleDelete = async (enrolleeId) => {
    await dispatch(deleteEnrollee(enrolleeId));
    await dispatch(getEnrolleesByUserId(user?._id));
  };

  useEffect(() => {
    dispatch(getEnrolleesByUserId(user?._id));
    dispatch(reset());
  }, []);

  return (
    <div>
      <Sidebar>
        <Header headerFor="dashboard" />
        {
          isLoading ?
            <div className="loader-container">
              <div className="loader primary"></div>
            </div> :
            <div className="p-2">
              <div className="md:px-5 md:py-3 2xl:p-5 flex flex-col md:flex-row justify-between items-center">
                <div>
                  <h1 className="font-urbanistBold md:text-[14px] lg:text-[17px] xl:text-[20px] 2xl:text-2xl">
                    My Enrollee list
                  </h1>
                  <p className="font-urbanistMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                    Here you will be able to see the information of each user.
                  </p>
                </div>
                <div className="w-[100%] flex justify-end md:w-auto">
                  <Link to="/add-enrollee">
                    <Button variant="filled" className="font-manropeMedium">
                      Add New Enrollee
                    </Button>
                  </Link>
                </div>
              </div>
              <div className="md:px-5 md:py-2 2xl:p-5 grid grid-cols-1 md:grid-cols-3 md:gap-3 2xl:gap-6">
                {enrollees &&
                  enrollees.map((enrollee, index) => (
                    <EnrolleeCard
                      key={enrollee._id}
                      profileImg={enrollee.profileImg}
                      enrolleeName={`${enrollee.firstName} ${enrollee.lastName}`}
                      enrolleeNum={index + 1}
                      allergyName={enrollee.allergyName}
                      allergyDesc={enrollee.allergyDescription}
                      contacts={enrollee.contactNumbers}
                      link={enrollee.link}
                      handleEdit={() => handleEdit(enrollee)}
                      handleDelete={() => handleDelete(enrollee._id)}
                    />
                  ))}
              </div>
            </div>
        }
      </Sidebar>
    </div>
  );
};

export default Dashboard;
