import React, {useEffect} from "react";
import { Navigate, Outlet } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {authenticateToken} from "./features/auth/authSlice";

const ProtectedRoute = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authenticateToken());
  }, [])

  if (!user || !user?.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return children || <Outlet />;
};

export default ProtectedRoute;
