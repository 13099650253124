import React from "react";
import ProfileIcon from "../svgs/ProfileIcon";

const ProfileUploader = ({ profileImg, handleProfileImgChange, isUserProfile }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="border border-stroke rounded-lg p-2 md:py-2 md:px-3 lg:p-3 flex items-center gap-2 md:gap-3 xl:gap-4">
        <div className="rounded-full border border-primary size-[80px] flex justify-center items-center">
          {profileImg ? (
            <img
              src={
                profileImg.startsWith("blob")
                  ? profileImg
                  : `${profileImg}`
              }
              alt="Profile"
              className="object-cover size-full rounded-full"
            />
          ) : (
            <ProfileIcon />
          )}
        </div>
        <div>
          <h2 className="font-manropeBold text-[12px] md:text-[14px] xl:text-xl">
            Upload a Profile Photo
          </h2>
          <p className="font-manropeMedium text-bodyText text-[10px] md:text-[12px] xl:text-lg">
            You can upload a picture from gallery or{" "}
            <span
              className="text-primary cursor-pointer"
              onClick={() => document.getElementById("fileInput").click()}
            >
              browse a file.
            </span>
          </p>
          <input
            type="file"
            id="fileInput"
            className="hidden"
            onChange={handleProfileImgChange}
            accept=".jpg,.png,.jpeg"
          />
        </div>
      </div>
      {
        isUserProfile ?
            <></> :
            <p className="text-primary font-urbanistMedium text-[14px] md:text-[12px] xl:text-lg">
              *For children under 18, please include a photo of the child with their parent
            </p>
      }
    </div>
  );
};

export default ProfileUploader;
