import React from "react";

const HomeIcon = () => {
  return (
    <svg
      className="md:size-4 lg:size-5 xl:size-6 2xl:size-7"
      width="30"
      height="31"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3586 7.17498L11.9003 2.80831C10.8336 1.95831 9.16696 1.94997 8.10863 2.79997L2.65029 7.17498C1.86696 7.79998 1.39196 9.04997 1.55863 10.0333L2.60863 16.3166C2.85029 17.725 4.15863 18.8333 5.58363 18.8333H14.417C15.8253 18.8333 17.1586 17.7 17.4003 16.3083L18.4503 10.025C18.6003 9.04997 18.1253 7.79998 17.3586 7.17498ZM10.6253 15.5C10.6253 15.8416 10.342 16.125 10.0003 16.125C9.65863 16.125 9.37529 15.8416 9.37529 15.5V13C9.37529 12.6583 9.65863 12.375 10.0003 12.375C10.342 12.375 10.6253 12.6583 10.6253 13V15.5Z"
        stroke="#0C0507"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
