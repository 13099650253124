import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { MdClose, MdDelete, MdEdit, MdSort } from "react-icons/md";
import Avatar from "./Avatar";
import HelloIcon from "../svgs/HelloIcon";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Button from "./Button";
import { deleteEnrollee, getEnrolleesByUserId } from "../../features/enrollee/enrolleeSlice";

const Header = ({ headerFor, name }) => {
  const { user } = useSelector((state) => state.auth);
  const { isLoading, enrollees } = useSelector((state) => state.enrollees);
  const [isSearchShown, setIsSearchShown] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredEnrollees, setFilteredEnrollee] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchText && enrollees) {
      const lowerCaseText = searchText.toLowerCase();
      setFilteredEnrollee(enrollees.filter(enrollee => enrollee.firstName.toLowerCase().includes(lowerCaseText) || enrollee.lastName.toLowerCase().includes(lowerCaseText)));
    } else {
      setFilteredEnrollee([]);
    }
  }, [searchText]);

  const onSearchToggle = () => {
    setIsSearchShown(!isSearchShown);
    setSearchText('')
  }

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  }

  const handleEdit = (enrollee) => {
    navigate("/add-enrollee", { state: { enrollee } });
  };

  const handleDelete = async (enrolleeId) => {
    await dispatch(deleteEnrollee(enrolleeId));
    await dispatch(getEnrolleesByUserId(user?._id));
    onSearchToggle()
  };

  return (
    <div className="border-b-2 border-stroke p-[0.5em] md:p-[0] sticky top-0 bg-primary">
      <div className="flex justify-between items-center md:p-3 2xl:p-5 flex-col lg:flex-row gap-[0.5em] lg:gap-[0px]">
        {headerFor === "dashboard" ? (
          <div className="flex items-center md:gap-2 lg:gap-3 2xl:gap-4 w-[100%]">
            <div className="mr-[0.25em] h-[30px] w-[30px]">
              <HelloIcon />
            </div>
            <div>
              <h1 className="font-urbanistBold text-[10px] md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                Welcome <span className="text-black">{user?.fullName}</span>
              </h1>
              <p className="text-black font-urbanistMedium text-[10px] md:text-[10px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px]">
                Hello, here you can manage your dashboard
              </p>
            </div>
          </div>
        ) : (
          <h1 className="text-[12px] md:text-[14px] lg:text-[17px] xl:text-[20px] 2xl:text-2xl font-urbanistBold text-nowrap">
            {name}
          </h1>
        )}

        {user?.isLoggedIn ? (
          <div className="flex gap-5 items-center w-[100%] justify-end">
            <div className='relative z-[10]'>
              <div className='flex items-center justify-center gap-[0.5em]'>
                {
                  isSearchShown ?
                    <input type="search"
                      className='p-[0.5em] border-[1px] border-gray-300 rounded-[8px] text-[0.8rem] lg:text-[1rem] focus:outline-none'
                      placeholder={'Search Enrollees'} onChange={onSearchTextChange} /> :
                    <></>
                }
                <div className="text-[15px] lg:text-[26px] cursor-pointer" onClick={onSearchToggle}>
                  {
                    isSearchShown ?
                      <MdClose /> :
                      <FiSearch />
                  }
                </div>
              </div>
              {
                isSearchShown && searchText ?
                  <div
                    className='absolute top-[150%] left-0 w-[300px] lg:w-[500px] max-h-[200px] overflow-x-hidden overflow-y-auto bg-white transform translate-x-[0] lg:translate-x-[-20%] shadow-md rounded-[8px] z-[10] p-[0.5em] flex flex-col gap-[0.5em]'>
                    {
                      isLoading ?
                        <div className="loader-container">
                          <div className="loader primary"></div>
                        </div> : (
                          filteredEnrollees && filteredEnrollees.length > 0 ?
                            filteredEnrollees.map((enrollee, index) => {
                              return (
                                <div
                                  className='flex justify-between items-center border-[1px] border-gray-200  px-[1em] py-[0.5em] rounded-[8px]'>
                                  <div className='flex gap-[0.5em] items-center justify-start'>
                                    {
                                      enrollee.profileImg ?
                                        <div
                                          className='h-[22px] w-[22px] lg:h-[30px] lg:w-[30px] rounded-[100%] overflow-hidden'>
                                          <img src={enrollee.profileImg} alt={'Profile Image'}
                                            className='w-[100%] h-[100%] ' />
                                        </div> : <></>
                                    }
                                    <p className="mb-0 font-bold text-[0.8rem] lg:text-[1rem]">{enrollee.firstName} {enrollee.lastName}</p>
                                  </div>
                                  <div className='flex items-center justify-center gap-[0.5em]'>
                                    <div
                                      className='cursor-pointer p-[0.25em] bg-gray-100 rounded-[4px] text-gray-500 transform duration-[0.3s] ease-in-out transition-transform hover:scale-[1.2]'
                                      onClick={() => handleEdit(enrollee)}>
                                      <MdEdit />
                                    </div>
                                    <div
                                      className='cursor-pointer p-[0.25em] bg-red-100 rounded-[4px] text-red-500 transform duration-[0.3s] ease-in-out transition-transform hover:scale-[1.2]'
                                      onClick={() => handleDelete(enrollee._id)}>
                                      <MdDelete />
                                    </div>
                                  </div>
                                </div>
                              )
                            }) :
                            <div className='text-center text-gray-500'>
                              No enrollee found
                            </div>
                        )
                    }
                  </div> : <></>
              }
            </div>
            <Link to={"/user-profile"} className="flex gap-2 items-center">
              <Avatar imgUrl={user.profileImage} />
              <p className="font-urbanistMedium text-[10px] md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                {user?.fullName}
              </p>
            </Link>
            <div className="text-[20px] block md:hidden" id="sidebarToggleBtn">
              <MdSort />
            </div>
          </div>
        ) : (
          <Link to="/login">
            <Button variant="filled">Login</Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Header;
