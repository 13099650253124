import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import InputField from "../components/common/InputField";
import Button from "../components/common/Button";
import FeaturedContent from "../components/common/FeaturedContent";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { login, reset } from "../features/auth/authSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const [agreeChecked, setAgreeChecked] = useState(false);

  const handleAgreeChange = () => {
    setAgreeChecked(!agreeChecked);
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(formData));
  };

  useEffect(() => {
    if (isError) {
      toast.error("Invalid Credentials!");
      dispatch(reset());
    }

    if (user?.isLoggedIn) {
      if (isSuccess) toast.success("Login successfull!");
      if (!user.onboarded) navigate("/add-enrollee");
      else navigate("/");
      dispatch(reset());
    }
  }, [dispatch, isSuccess, isError, message]);

  return (
    <div className="flex justify-between h-[100vh] min-h-[300px] md:min-h-[800px]">
      <div className="flex-1 justify-center items-center relative hidden lg:flex">
        <FeaturedContent />
      </div>
      <div className="flex-1 flex justify-center items-center">
        <div className="max-w-[84%] md:max-w-[40%] lg:max-w-[60%] flex flex-col items-center justify-start gap-3 md:gap-1 lg:gap-2 xl:gap-4 2xl:gap-8">
          <img
            src="/assets/imgs/logo.png"
            className="w-[120px] h-[60px] md:w-[70px] md:h-[35px] lg:w-[120px] lg:h-[60px] xl:w-[150px] xl:h-[75px] 2xl:w-[182px] 2xl:h-[90px]"
            alt="company-logo"
          />
          <div className="text-center space-y-2 md:space-y-1 xl:space-y-3">
            <h1 className="font-urbanistSemibold text-[20px] md:text-lg lg:text-[20px] xl:text-2xl 2xl:text-4xl">
              Sign In to your account
            </h1>
            <p className="font-urbanistMedium text-bodyText leading-tight text-[14px] xl:text-[19px] 2xl:text-xl">
              Enter your credentials to explore all features of the website.
            </p>
          </div>

          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col gap-3 md:gap-2 xl:gap-4 2xl:gap-6"
          >
            <InputField
              name="email"
              label="Email Address"
              type="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleChange}
              required={true}
            />

            <InputField
              name="password"
              label="Password"
              type="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              required={true}
            />

            <Link
              to={"/forget-password"}
              className="text-primary font-manropeMedium text-[14px] md:text-[12px] xl:text-[16px] 2xl:text-lg self-end"
            >
              Forget Password?
            </Link>

            <Button
              type="submit"
              variant="filled"
              className="flex justify-center items-center"
            >
              {isLoading ? <div className="loader"></div> : "Login"}
            </Button>
          </form>

          <div className="mt-3 flex flex-col text-center text-[14px] gap-3 md:gap-2 xl:gap-3 2xl:text-lg md:text-[12px] xl:text-[16px] 2xl:gap-5">
            <p className="font-manropeSemibold me-[0.25em] flex items-center justify-center gap-[0.25em]">
              Don't have an Account?
              <Link to={"/create-account"} className="text-primary block">
                Create Now
              </Link>
            </p>
            <p className="leading-tight font-manropeMedium">
              For any inquiries, please email us at
              <a href="mailto:support@thereunite.com" className="text-primary">support@thereunite.com</a>
              or
              see our{" "}
              <Link to="/privacy-and-security" className="text-primary">
                Privacy Policy
              </Link>
              ,{" "}
              <Link to="/terms-and-conditions" className="text-primary">
                Terms and Conditions
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
